import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import Container from "../../../components/Container";
import { PrimaryButton } from "../../../components/CustomButton";
import { infoNotifier } from "../../../components/NotificationHandler";
import { AuthContext } from "../../../context/authContext";
import { resetCode } from "../service/resetCode";
import { BsLock } from "react-icons/bs";
import { COLORS } from "../../../constants/colors";

export const UpdatePassword = ({ data }) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const pageBg = useColorModeValue(COLORS.white, COLORS.black);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setIsLoggedin } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) return infoNotifier("Please enter a password and confirm it.");
    if (password !== confirmPassword) return infoNotifier("Passwords do not match.");

    const payload = {
      newTenantCode: password,
      email: data?.data?.tenant?.email,
      userId: data?.data?.userData?.userId,
    };

    resetCode(payload, setLoading, setIsLoggedin);
  };

  return (
    <SlideFade in={true} offsetX="-5000px">
      <Container bg={pageBg}>
        {/* New password */}
        <Stack w="100%" as="section" pt="15vh" h={"100vh"}>
          <Text fontWeight="600" fontSize="2.5rem" color={cardBg}>
            Reset Password
          </Text>
          <Text fontSize="1.0rem" color={cardBg}>
            Set up your new password
          </Text>
          <FormControl w="100%">
            <InputGroup>
              <Input
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Password"
                _placeholder={{ color: `${COLORS.green}` }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputLeftElement children={<BsLock />} mt="10px" />
            </InputGroup>
            <InputGroup my="50px">
              <Input
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Confirm Password"
                _placeholder={{ color: `${COLORS.green}` }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <InputLeftElement children={<BsLock />} mt="10px" />
            </InputGroup>
            <PrimaryButton
              loadingText="Resetting"
              btnText="Submit"
              isLoading={loading}
              onClick={handleLogin}
              h="60px"
              fontSize="18px"
              rounded="2xl"
            />
          </FormControl>
        </Stack>
      </Container>
    </SlideFade>
  );
};

export const inputStyles = {
  bg: "white",
  rounded: "2xl",
  h: "60px",
  color: "#262636",
};
