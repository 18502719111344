import { errorNotifier, successNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const fetchStreetsData = async (setStreetsData, setLoadingStreets) => {
  try {
    setLoadingStreets(true);
    const { data } = await http.get("/user/house");
    
    // Transform the data into a format that allows mapping by street
    const transformedData = data.result.data.houses.reduce((acc, house) => {
      const { streetId, houseNumber } = house;
      const { _id: streetIdValue, streetName } = streetId;

      // Ensure each street has an entry with its name and corresponding house numbers
      if (!acc[streetIdValue]) {
        acc[streetIdValue] = {
          streetName,            // Display name
          streetId: streetIdValue, // Backend ID
          houses: []               // Array to hold house numbers
        };
      }

      // Add house numbers to the corresponding street
      acc[streetIdValue].houses.push(houseNumber);
      return acc;
    }, {});

    // Set the transformed data in state
    setStreetsData(transformedData);
    setLoadingStreets(false);
    console.log("Transformed Data: ", transformedData);
  } catch (error) {
    errorNotifier(error.response?.data?.result?.message);
    console.error(error.response?.data?.result?.message);
    setLoadingStreets(false);
  }
};



export const signupTenant = async (payload, setLoading) => {
  try {
    setLoading(true);
    const { data } = await http.post(AUTH_ROUTES.SIGNUP, payload);

    if (data.result.success) {
        successNotifier(data.result?.message);
        return true; 
    } else {
      errorNotifier("Signup failed. Please try again.");
      return false; 
    }
  } catch (e) {
    if (e?.response?.data?.errors) {
        const errorMessages = e.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        errorNotifier(errorMessages || "Signup failed with errors.");
      } else {
        errorNotifier("Please check your network connection!!!");
      }
  } finally {
    setLoading(false);
  }
};
