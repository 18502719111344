import {
  Box,
  Circle,
  Flex,
  HStack,
  Select,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { inviteGuest } from "../service/inviteGuest";
import { daysOfTheWeek } from "./inviteData";
import RandomPicker from "./RandomPicker";
import RandomSummary from "./RandomSummary";
import RangeSummary from "./RangeSummary";
import ShareInviteLink from "./ShareInviteLink";
import ShareModal from "./ShareModal";
import Summary from "./Summary";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import { timeout } from "../../../components/utils/timeout";
import { getDatesWithinDateRange } from "../../../components/utils/getNumberOfWeeksBtwTwoDates";
import { UserProfile } from "../../../context/userProfileContext";

const CustomizeVisitModal = (props) => {
  const today = new Date();
  const navigate = useNavigate();
  const { MY_ESTATE } = PROTECTED_PATHS;
  const [recurrence, setRecurrence] = useState("Weekly");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [days, setDays] = useState([]);
  const [dates, setDates] = useState([]);
  const [allowCompany, setAllowCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guestCode, setGuestCode] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRandomDates, setSelectedRandomDates] = useState([]);
  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;

  const initialValues = {
    tenantCode,
    userId: _id,
    name: props.name,
    phone: props.phone,
    oneTimeVisit: props.isChecked ? 'no' : 'no',
    allowCompany,
    startDate: dayjs(today).format("YYYY-MM-DD"),
    endDate: dayjs(today).format("YYYY-MM-DD"),
    days: [],
    dates: [],
  };

  const [customVisitValues, setCustomVisitValues] = useState(initialValues);

  const calculateDatesForSelectedDays = () => {
    const datesArray = getDatesWithinDateRange(startDate, endDate, recurrence === "Weekly" ? days : []);
    
    setDates(datesArray.datesOfDatesWithinRange);
    setCustomVisitValues((values) => ({
      ...values,
      dates: datesArray.datesOfDatesWithinRange,
    }));
  };
  

  const handleStartDate = (date) => {
    setStartDate(date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setCustomVisitValues((values) => ({
      ...values,
      startDate: formattedDate,
    }));

    calculateDatesForSelectedDays();
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setCustomVisitValues((values) => ({
      ...values,
      endDate: formattedDate,
    }));

    calculateDatesForSelectedDays();
  };

  const handleRepeatOn = (e) => {
    if (days.includes(e.target.innerText)) {
      removeDay(e.target.innerText);
    } else {
      const newDays = [...days, e.target.innerText];
      setDays(newDays);

      setCustomVisitValues((values) => ({
        ...values,
        recurring: true,
        days: newDays,
      }));
      calculateDatesForSelectedDays();
    }
  };

  const removeDay = (day) => {
    const remainingSelectedDays = days.filter((d) => d !== day);
    setDays(remainingSelectedDays);

    setCustomVisitValues((values) => ({
      ...values,
      days: remainingSelectedDays,
    }));
    calculateDatesForSelectedDays();
  };

  const handleAllowCompany = () => {
    setAllowCompany(!allowCompany);
    setCustomVisitValues((values) => ({
      ...values,
      allowCompany: !allowCompany,
    }));
  };

  const handleCustomInvite = () => {
    inviteGuest(
      customVisitValues,
      setLoading,
      setGuestCode,
      onOpen,
      onClose,
      navigate
    );
  };

  const handleShareClose = () => {
    onClose();
    timeout(() => navigate(MY_ESTATE), 1000);
  };

  useEffect(() => {
    calculateDatesForSelectedDays();
  }, [startDate, endDate, days]);

  return (
    <>
      <Stack spacing="4">
        <Text fontWeight="600" textAlign="center" fontSize="1.2rem">
          Customize your visit
        </Text>
        <HStack gap="1rem">
          <Select
            size="sm"
            maxW="150px"
            value={recurrence}
            onChange={(e) => setRecurrence(e.target.value)}
          >
            <option>Weekly</option>
            <option>Random dates</option>
            <option>Date Range</option>
          </Select>
        </HStack>
        {recurrence === "Weekly" ? (
          <Box>
            <Text>Repeat on</Text>
            <HStack wrap="wrap">
              {daysOfTheWeek.map((day) => (
                <Circle
                  h="40px"
                  cursor="pointer"
                  w="40px"
                  key={day}
                  name={day}
                  bg={days.includes(day) ? "primaryDark" : "bgColorLight"}
                  color={days.includes(day) ? "#fff" : ""}
                  fontWeight={600}
                  fontSize="12px"
                  _active={{ bg: "lightPink" }}
                  onClick={handleRepeatOn}
                >
                  {day}
                </Circle>
              ))}
            </HStack>
          </Box>
        ) : recurrence === "Random dates" ? (
          <Box>
            <RandomPicker
              setSelectedRandomDates={setSelectedRandomDates}
              setCustomVisitValues={setCustomVisitValues}
            />
          </Box>
        ) : null}

        <Box>
          <Text pr="3" as="small">
            Allow Company
          </Text>
          <Switch isChecked={allowCompany} onChange={handleAllowCompany} />
        </Box>

        {recurrence !== "Random dates" && (
          <Flex justify="space-between" wrap="wrap" py="5">
            <Stack zIndex="10">
              <Text>Start date</Text>
              <DatePicker
                minDate={new Date()}
                dateFormat={"dd/MM/yyyy"}
                selected={startDate}
                onChange={(date) => handleStartDate(date)}
              />
            </Stack>
            <Stack>
              <Text>End date</Text>
              <DatePicker
                minDate={new Date()}
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => handleEndDate(date)}
              />
            </Stack>
          </Flex>
        )}

        <CautionAlertDialog
          onContinue={handleCustomInvite}
          loading={loading}
          button="Send Invite"
          buttonProps={{ bg: "primaryDark" }}
        >
          {recurrence === "Date Range" ? (
            <RangeSummary customVisitValues={customVisitValues} />
          ) : recurrence === "Random dates" ? (
            <RandomSummary
              selectedRandomDates={selectedRandomDates}
              customVisitValues={customVisitValues}
            />
          ) : (
            <Summary
              getDatesWithinDateRange={() =>
                getDatesWithinDateRange(startDate, endDate)
              }
              customVisitValues={customVisitValues}
              name={props.name}
              phone={props.phone}
            />
          )}
        </CautionAlertDialog>
      </Stack>
      <ShareModal isOpen={isOpen} onOpen={onOpen} onClose={handleShareClose}>
        <ShareInviteLink onClose={onClose} guestCode={guestCode} />
      </ShareModal>
    </>
  );
};

export default CustomizeVisitModal;