import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/login/Login';
import { PUBLIC_PATHS } from '../app/constants';
import InTroScreen from '../pages/intro screen';
import { RequestPasswordReset } from '../pages/login/components/RequestPasswordReset';
import Signup from '../pages/signup/Signup';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
// import { ValidateOTP } from '../pages/login/components/ValidateOTP';

const UnAuthenticatedApp = () => {
  const { LOGIN, LANDING, FORGOTPASSWORD, PRIVACY_POLICY,SIGNUP } = PUBLIC_PATHS;

  return (
    <Routes>
      <Route path={LANDING} element={<InTroScreen />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path={FORGOTPASSWORD} element={<RequestPasswordReset />} />
      <Route path={SIGNUP} element={<Signup />} />
      <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
      {/* <Route path={RESET_PASSWORD} element={<ValidateOTP />} /> */}
      <Route path="/*" element={<Navigate to={LOGIN} replace />} />
    </Routes>
  );
};

export default UnAuthenticatedApp;
