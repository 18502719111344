/**
 * Paths available to users who are not logged in
 * @constant
 */
export const PUBLIC_PATHS = {
  LANDING: "/",
  LOGIN: "/login",
  FORGOTPASSWORD: "/forgot-password",
  SIGNUP: "/signup",
  RESET_PASSWORD: "/reset-password",
  PRIVACY_POLICY: "/privacy-policy",
};

/**
 * Paths available to users who are  logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  MY_ESTATE: "/my-estate",
  MY_WALLET: "/my-wallet",
  SERVICES: "/services",
  CABLE_TV: "/cable-tv",
  POWER_UTILITY: "/power-and-utility",
  // EKEDC_PAYMENT: '/ekedc-payment',
  EKEDC_PAYMENT: "/power-and-utility/:provider/:discoName",
  DSTV_PAYMENT: "/dstv-payment",
  GOTV_PAYMENT: "/gotv-payment",
  STARTIMES_PAYMENT: "/startimes-payment",
  //////
  MTN_AIRTIME: "/mtn-airtime",
  GLO_AIRTIME: "/glo-airtime",
  AIRTEL_AIRTIME: "/airtel-airtime",
  ETISALAT_AIRTIME: "/etisalat-airtime",
  ///
  MTN_DATA: "/mtn-data",
  GLO_DATA: "/glo-data",
  ETISALAT_DATA: "/etisalat-data",
  AIRTEL_DATA: "/airtel-data",

  MY_ERRANDS: "/my-errands",
  SELECTED_ERRANDS: "/selected-errands",
  ERRAND_HOME: "/errand-home",
  ERRAND_PAYMENT_GATEWAY: "/errand-payment-gateway",
  NOTIFICATIONS: "/notifications",
  NEWS_EVENTS: "/new-events",
  INVITE_GUEST: "/invite-guest",

  ISSUE_REPORT: "/issue-report",
  ISSUE_REPORTS_HISTORY: "/issue-reports-history",
  ONE_ISSUE_REPORT: "/one-issue-report",
  VIEW_REPORT_DETAILS: "/issue-reports-history/:reportId",
  SEND_MESSAGE_TO_ADMIN: "/issue-reports-history/message/:issuedID",

  ESTATE_DUE: "/estate-due",
  EMERGENCY: "/emergency",
  GUEST_LIST: "/guest-list",
  SETTINGS: "/settings",
  MARKET_PLACE: "/market-place",
  ALL_OFFERS: "/all-offers",
  TOP_PRODUCT_SCREEN: "/top-product-screen/:productId",
  OFFER_JOINT_ONE: "/offer/joint-offer/:offerId",
  POST_ADVERT: "/post-advert",
  FILTER_PAGE: "/filter-page",
  CATEGORY: "/produts/:category",
  ALL_CATEGORY: "/market-place/all-category",
  SAME_CATEGORY: "/market-place/categoryItems",
  SPECIFIC_PRODUCT: "/market-place/specificCategory",
  ITEM_DESCRIPTION: "/market-place/itemDescription",
  ALL_TOP_PRODUCT: "/market-place/all-top-product",

  // billing analysis
  ALL_TRANSACTIONS: "/transactions",
  TRANSACTION_DETAILS: "/transaction-details",
  BILLING_ANALYSIS: "/billing-analysis",
  // PENDING_BILLS: "/my-wallet/pay-bills",
  ESTATE_BILLS: "/my-wallet/estate-bills",
  ACCOUNT_STATEMENT: "/account-statement",

  // special request
  SPECIAL_GUEST_REQUEST: "/special-guest-request",
  PARTY_EVENT: "/special-guest-request/party-event",
  VEHICLES: "/special-guest-request/vehicles",
  CONTRACTOR_ARTISAN: "/special-guest-request/contractor-artisan",
  PARTY_EVENT_REQUEST: "/special-guest-request/make-request",
  CONTRACTOR_LIST: "/special-guest-request/contractor-list",
  VEHICLE_LIST: "/special-guest-request/vehicle-list",
  EVENT_LIST: "/special-guest-request/event-list",

  // PAYMENT_METHOD: '/special-guest-request/payment-method',
  E_RECEIPT: "/special-guest-request/e-receipt",
  ADD_GUEST: "/special-guest-request/add-guest/guest-list/:eventId",
  EDIT_REQUEST: "/special-guest-request/:requestId",
  EVENT_GUEST_LIST: "/special-guest-request/guest-list/:eventId",

  //Shop
  SHOP: "/shop",
  EDIT_SHOP_PRODUCT: "/shop/archieve/edit/:shopProduct",
  VIEW_ONE_SHOP_PRODUCT: "/shop/product/:shopProduct",
  VIEW_ONE_ARCHIEVED_PRODUCT: "/shop/archieve/:archievedProduct",

  //polls
  POLLS: "/polls",
  VOTE: "/polls/vote/:voteID",
  CREATE_POLLS: "/polls/createPolls",

  //IdCard
  ID_CARD: "/IdCard",
  REQUEST_ID: "/Request-id",

  // Chat
  CHAT: "/chat-with-MyEstateNG",
  
  // Video
  VIDEO: "/how-to-video"
};

export const DATA_ROWS = {
  LIMIT: 20,
};
