import { Grid, Img } from "@chakra-ui/react";

const SplashScreen = () => {
  return (
    <Grid w="100vw" h="100vh" placeContent="center">
      <Img src="/silverland-logo192.png" alt="Silverland Zone" />
    </Grid>
  );
};

export default SplashScreen;
