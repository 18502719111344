import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

// export const inviteGuest = async (payload, setLoading, setGuestCode, onOpen, onClose, navigate) => {
//   try {
//     setLoading(true);
//     const {
//       data: { result },
//     } = await http.post(AUTH_ROUTES.INVITE_GUEST, payload);
//     setGuestCode(result.data.guestCode);

//     successNotifier(result?.message);

//     setLoading(false);
//     result.data.guestCode && onOpen();

//     // onClose();
//   } catch (error) {
//     onClose();
//     setLoading(false);

//     if (!error.response) {
//       errorNotifier('Please Check your internet');
//       return;
//     } else {
//       const message = error.response.data?.message || error.response.data?.result?.message || 'An error occurred';
//       errorNotifier(message);
//     }
//     //error.response.data.message ? errorNotifier(error.response.data.message) : errorNotifier(error.response.data.result.message);
//   }
// };

export const inviteGuest = async (payload, setLoading, setGuestCode, onOpen, onClose, navigate) => {
  try {
    setLoading(true);
    const { data: { result } } = await http.post(AUTH_ROUTES.INVITE_GUEST, payload);
    
    setGuestCode(result.data.guestCode);  // Ensure this exists in the response
    successNotifier(result?.message);

    setLoading(false);
    result.data.guestCode && onOpen();
  } catch (error) {
    if (error?.response?.data?.result?.data?.message) {
      // Extract the message from the result object
      const errorMessage = error.response.data.result.data.message || "Signup failed with errors.";
      errorNotifier(errorMessage);
  }
    setLoading(false);
    onClose();

    // Enhanced error handling
    console.error("Error inviting guest:", error);  // Log the error to the console

    if (!error.response) {
      errorNotifier('Please check your internet connection.');
      return;
    }
    
    // const errorMessage = error.response?.data?.message || error.response?.data?.result?.message || "An unknown error occurred.";
    // errorNotifier(errorMessage);
  }
};
