import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlinePhone } from "react-icons/ai";
import Container from "../../components/Container";
import { PrimaryButton } from "../../components/CustomButton";
import { errorNotifier } from "../../components/NotificationHandler";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants/colors";
import { PUBLIC_PATHS } from "../../app/constants";
import { fetchStreetsData, signupTenant } from "./service/signup";

const { LOGIN } = PUBLIC_PATHS;

const Signup = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const pageBg = useColorModeValue(COLORS.white, COLORS.black);

  // Signup form states
  const [streetId, setStreetId] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [tenantPhone, setTenantPhone] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [loading, setLoading] = useState(false);

  // Backend data states
  const [streetsData, setStreetsData] = useState({});
  const [loadingStreets, setLoadingStreets] = useState(true);

  // Fetch streets and house numbers from backend on component mount
  useEffect(() => {
    const loadStreetsData = async () => {
      try {
        await fetchStreetsData(setStreetsData, setLoadingStreets);
        console.log("Loaded streetsData:", streetsData); // Check structure here
      } catch (error) {
        console.error("Failed to fetch streets data:", error);
        setLoadingStreets(false);
        errorNotifier("Could not load streets data.");
      }
    };
    loadStreetsData();
  }, []);

  const handleStreetChange = (e) => {
    const selectedStreetId = e.target.value;
    setStreetId(selectedStreetId); // Set the street ID to be sent to the backend
    setHouseNumber(""); // Reset house number when street changes
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const payload = {
      houseNumber,
      streetId,
      houseUnit: "",
      tenantName,
      tenantPhone,
      email,
      designation,
    };

    if (!houseNumber || !streetId || !tenantName || !tenantPhone || !email || !designation) {
      errorNotifier("Please fill in all fields");
      return;
    }

    const success = await signupTenant(payload, setLoading);
    if (success) {
      window.location.href = LOGIN;
    }
  };

  return (
    <SlideFade in={true} offsetX="-5000px">
      <Container bg={pageBg}>
        <Stack w="100%" as="section" pt="15vh" h="100vh">
          <Text color={cardBg} fontWeight="600" fontSize="2.7rem">
            Sign Up
          </Text>
          <Text color={cardBg} fontWeight="500" fontSize="1.2rem">
            Kindly fill up the details below
          </Text>
          <FormControl w="100%">
            <Flex my="7" gap="4">
              {/* Street Select */}
              <InputGroup flex="1">
                <Select
                  {...inputStyles}
                  borderColor={COLORS.green}
                  placeholder="Select Street"
                  _placeholder={{ color: COLORS.green }}
                  value={streetId}
                  onChange={handleStreetChange}
                  isDisabled={loadingStreets}
                >
                  {Object.values(streetsData).map(({ streetName, streetId }) => (
                    <option key={streetId} value={streetId}>
                      {streetName}
                    </option>
                  ))}
                </Select>
              </InputGroup>

              {/* House Number Select */}
              <InputGroup flex="1">
                <Select
                  {...inputStyles}
                  borderColor={COLORS.green}
                  placeholder="Select House"
                  _placeholder={{ color: COLORS.green }}
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                  isDisabled={!streetId}
                >
                  {streetId &&
                    streetsData[streetId]?.houses.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                </Select>
              </InputGroup>
            </Flex>

            {/* Designation Select Field */}
            <InputGroup my="7">
              <Select
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Designation"
                _placeholder={{ color: COLORS.green }}
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              >
                <option value="tenant">Tenant</option>
                <option value="landlord">Landlord</option>
                <option value="agent">Agent</option>
                <option value="business">Business</option>
              </Select>
            </InputGroup>

            {/* Full Name Input Field */}
            <InputGroup my="7">
              <Input
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Full Name"
                _placeholder={{ color: COLORS.green }}
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
              />
            </InputGroup>

            {/* Phone Number Input Field */}
            <InputGroup my="7">
              <Input
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Phone Number"
                _placeholder={{ color: COLORS.green }}
                value={tenantPhone}
                type="number"
                onChange={(e) => setTenantPhone(e.target.value)}
              />
              <InputLeftElement children={<AiOutlinePhone />} mt="10px" />
            </InputGroup>

            {/* Email Input Field */}
            <InputGroup my="7">
              <Input
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Email"
                _placeholder={{ color: COLORS.green }}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>

            {/* Signup Button */}
            <PrimaryButton
              loadingText="Signing you up"
              btnText="Sign up"
              isLoading={loading}
              onClick={handleSignup}
              h="60px"
              fontSize="18px"
              rounded="2xl"
            />

            {/* Login Link */}
            <Text
              fontSize="16px"
              textAlign="center"
              fontWeight={600}
              color={cardBg}
              mb="20px"
            >
              Already have an account?{" "}
              <Link
                to={LOGIN}
                style={{ color: COLORS.green, textDecoration: "underline" }}
              >
                Log In
              </Link>
            </Text>
          </FormControl>
        </Stack>
      </Container>
    </SlideFade>
  );
};

export default Signup;

export const inputStyles = {
  bg: "white",
  rounded: "2xl",
  h: "60px",
  color: "#262636",
};
